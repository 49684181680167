import { useState } from 'react'

export default function Question({ data }: any) {
  const [height, setHeight] = useState<any>(0)
  const { id, question } = data

  if (data?.link) {
    const { link } = data

    link.map(
      ({ tag, href }: any) =>
        (data.answer = data.answer.replace(
          new RegExp(tag, 'g'),
          `<a href=${href}>${tag}</a>`
        ))
    )
  }

  return (
    <div className="containerQuestion">
      <button
        onClick={() => {
          setHeight((prevState: any) => {
            return prevState === 0 ? 'auto' : 0
          })
        }}
      >
        <h3>{`${id}. ${question}`}</h3>
      </button>
      <div
        id="example-panel"
        className={`${height === 0 ? 'question-closed' : 'question-open'}`}
      >
        <p dangerouslySetInnerHTML={{ __html: data.answer }} />
      </div>
    </div>
  )
}
